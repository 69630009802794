import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  title: '',
  handleBackClick: null,
  buttonList: [],
  searchToolConfig: {},
}

const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    setHeaderState(state, { payload }) {
      state.title = payload.title || '';
      state.handleBackClick = payload.handleBackClick || null,
      state.buttonList = payload.buttonList || [];
      state.searchToolConfig = payload.searchToolConfig || {};
    }
  },
  extraReducers: {}
})

export const { setHeaderState } = headerSlice.actions
export default headerSlice.reducer
