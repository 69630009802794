import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { createWrapper, HYDRATE } from "next-redux-wrapper"
import adminsReducer from "./slices/admins"
import authReducer from "./slices/auth"
import customersReducer from "./slices/customers"
import resourcesReducer from "./slices/resources"
import TMJobTypesReducer from "./slices/TM-job-types"
import serviceDetailsReducer from "./slices/service-details"
import statusBarReducer from "./slices/status-bar"
import searchToolReducer from "./slices/search-tool"
import modalSettingsReducer from "./slices/modal-settings"
import sidebarReducer from "./slices/sidebar"
import headerReducer from "./slices/header"

let store

const combinedReducer = combineReducers({
  admins: adminsReducer,
  auth: authReducer,
  customers: customersReducer,
  resources: resourcesReducer,
  TMJobTypes: TMJobTypesReducer,
  serviceDetails: serviceDetailsReducer,
  statusBar: statusBarReducer,
  searchTool: searchToolReducer,
  modalSettings: modalSettingsReducer,
  sidebar: sidebarReducer,
  header: headerReducer,
})

const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload // apply delta from hydration
    }
    return nextState
  } else {
    return combinedReducer(state, action)
  }
}

export const makeStore = () => {
  store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false
      }),
    devTools: process.env.NODE_ENV !== "production"
  })
  return store
}
  
export { store }

export const wrapper = createWrapper(makeStore)
