import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  expand: true,
  choosedItem: '',
  choosedCategory: '',
}

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setExpand(state, { payload }) {
      state.expand = payload
    },
    setChoosedItem(state, { payload }) {
      state.choosedItem = payload
    },
    setChoosedCategory(state, { payload }) {
      state.choosedCategory = payload
    },
  },
  extraReducers: {}
})

export const { setExpand, setChoosedItem, setChoosedCategory } = sidebarSlice.actions
export default sidebarSlice.reducer
