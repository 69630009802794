import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { ServiceDetailsApi } from "../apis"

export const getServiceDetails = createAsyncThunk("serviceDetails/getServiceDetails", async (payload, { dispatch }) => {
  const data = await ServiceDetailsApi.getServiceDetails()
  dispatch(setServiceDetailsList({ data: data.results || [] }))
  return data
})

export const postServiceDetails = createAsyncThunk("serviceDetails/postServiceDetails", async (payload) => {
  return await ServiceDetailsApi.postServiceDetails({ serviceDetails: payload })
})

export const deleteServiceDetails = createAsyncThunk("serviceDetails/deleteServiceDetails", async (payload) => {
  return await ServiceDetailsApi.deleteServiceDetails({ serviceDetailIds: payload })
})

const initialState = {
  list: []
}

const serviceDetailsSlice = createSlice({
  name: "serviceDetailsSlice",
  initialState,
  reducers: {
    setServiceDetailsList(state, { payload }) {
      state.list = payload.data
    }
  },
  extraReducers: {}
})

export const { setServiceDetailsList } = serviceDetailsSlice.actions
export default serviceDetailsSlice.reducer
